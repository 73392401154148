import type { ContentCardDemoProps, ContentMetadata } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaBadge } from "@/components/general/GaBadge";
import { GaButton } from "@/components/general/GaButton";
import { ContentCard, getInitialSuspendTranslationFns, type TranslationFnAsync, type TranslationFnsAsync } from "@/components/cards/ContentCard";
import { defineComponent, type PropType, ref } from "vue";
import { createStore, type Store } from "@/common/storeUtils";
import { ensureNonNull } from "@utils/assertion";

function Demo(props: {
  contentCardDemoProps: ContentCardDemoProps;
  title: string;
  layout: "horizontal" | "vertical";
  columnCount: number;
  updateColumnCount: (n: number) => void;
  translationFns: Store<TranslationFnsAsync>;
}): GaVueComponent {
  const { summaries, miabs, actionables, sketchnotes } = props.contentCardDemoProps;
  const numberOfElements = summaries.length + actionables.length + sketchnotes.length + miabs.length;

  const additionalParams = new URLSearchParams();
  additionalParams.append("st", "ACTIONABLE");
  additionalParams.append("si", "1");

  function helper(content: ContentMetadata | undefined, cb: (content: ContentMetadata, translationFn: Store<TranslationFnAsync>) => GaVueComponent): GaVueComponent {
    return cb(ensureNonNull(content), props.translationFns.sub(ensureNonNull(content).common.language));
  }

  return (
    <div class="mb-5">
      <h2>{props.title}</h2>
      <div data-card-demo="">
        <div class="demo-bar mb-3">
          <span>
            <GaBadge variant="muted">VUE</GaBadge>
          </span>
          <div class="demo-bar-controls">
            <GaButton size={"sm"} onClick={() => props.updateColumnCount(Math.min(props.columnCount + 1, numberOfElements))}>
              +
            </GaButton>
            <GaButton size={"sm"} onClick={() => props.updateColumnCount(Math.max(props.columnCount - 1, 1))}>
              -
            </GaButton>
          </div>
        </div>
        <div class="demo-flexible-columns" style={`--row-number: ${props.columnCount}`}>
          {helper(props.contentCardDemoProps.summaries[0], (content, t) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.actionables[0], (content, t) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.sketchnotes[0], (content, t) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.miabs[0], (content, t) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.summaries[1], (content, t) => (
            <ContentCard content={content} additionalParams={additionalParams} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.actionables[1], (content, t) => (
            <ContentCard content={content} additionalParams={additionalParams} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.sketchnotes[1], (content, t) => (
            <ContentCard content={content} additionalParams={additionalParams} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.miabs[1], (content, t) => (
            <ContentCard content={content} additionalParams={additionalParams} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.summaries[2], (content, t) => (
            <ContentCard content={content} link="/free-abstracts/123?u=gA_Demo" allowDelete={true} additionalParams={additionalParams} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.actionables[2], (content, t) => (
            <ContentCard content={content} link="/free-abstracts/123?u=gA_Demo" allowDelete={true} additionalParams={additionalParams} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.sketchnotes[2], (content, t) => (
            <ContentCard content={content} link="/free-abstracts/123?u=gA_Demo" allowDelete={true} additionalParams={additionalParams} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.miabs[2], (content, t) => (
            <ContentCard content={content} link="/free-abstracts/123?u=gA_Demo" allowDelete={true} additionalParams={additionalParams} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.summaries[3], (content, t) => (
            <ContentCard content={content} allowBookmark={false} allowDelete={true} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.actionables[3], (content, t) => (
            <ContentCard content={content} allowBookmark={false} allowDelete={true} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.sketchnotes[3], (content, t) => (
            <ContentCard content={content} allowBookmark={false} allowDelete={true} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.miabs[3], (content, t) => (
            <ContentCard content={content} allowBookmark={false} allowDelete={true} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.summaries[4], (content, t) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.actionables[4], (content, t) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.sketchnotes[4], (content, t) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} />
          ))}
          {helper(props.contentCardDemoProps.miabs[4], (content, t) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} />
          ))}
        </div>
      </div>
    </div>
  );
}

export const ContentCardDemo = defineComponent({
  props: {
    contentCardDemoProps: {
      type: Object as PropType<ContentCardDemoProps>,
      required: true,
    },
  },
  setup(props) {
    const columns = ref({ horizontal: 2, vertical: 3 });
    const translationFns = createStore<TranslationFnsAsync>(getInitialSuspendTranslationFns());
    return () => (
      <div>
        <Demo contentCardDemoProps={props.contentCardDemoProps} title={"Vertical"} layout={"vertical"} columnCount={columns.value.vertical} updateColumnCount={(n) => (columns.value.vertical = n)} translationFns={translationFns} />
        <Demo contentCardDemoProps={props.contentCardDemoProps} title={"Horizontal"} layout={"horizontal"} columnCount={columns.value.horizontal} updateColumnCount={(n) => (columns.value.horizontal = n)} translationFns={translationFns} />
      </div>
    );
  },
});
