import { ensureNonNull } from "@utils/assertion";
import { type MIABCustomQuestionsResponse } from "@newgenerated/shared/schema";
import { serialize_MIABCustomQuestionsResponse, deserialize_MIABCustomQuestionsResponse } from "@newgenerated/shared/mapper";

export { type MIABCustomQuestionsResponse } from "@newgenerated/shared/schema";
export const parseMIABCustomQuestionsResponse = (data: unknown): MIABCustomQuestionsResponse => {
  return ensureNonNull(deserialize_MIABCustomQuestionsResponse(JSON.stringify(data)));
};
export const renderMIABCustomQuestionsResponse = (data: MIABCustomQuestionsResponse): any => {
  return JSON.parse(serialize_MIABCustomQuestionsResponse(data));
};
