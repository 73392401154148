import type { GaVueComponent } from "@/common/vueUtils";
import { GaBadge } from "@/components/general/GaBadge";
import { GaNumberedList } from "@/components/general/GaNumberedList";
import type { Store } from "@/common/storeUtils";
import { GaButton } from "@/components/general/GaButton";
import type { DiscussState } from "@/components/miab/MIABPage";
import { MIAB_MAX_QUESTIONS } from "@/components/miab/MIABPage";
import type { MIABPageProps, MIABQuestionMetadata } from "@newgenerated/shared/schema";
import { saveCustomQuestion } from "@generated/api/mIABControllerApi";
import { assert } from "@utils/assertion";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";
import { Edit } from "@/components/miab/pageComponents/Edit";
import { useConfirmationModal } from "@/components/general/GaModal";
import { addToast } from "@/components/general/GaToast";
import { DateTimeUtils } from "@utils/type/DateTimeUtils";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import { GaAlert } from "@/components/general/GaAlert";
import type { DateTime } from "luxon";

async function save(state: Store<DiscussState>, dataId: number, t: TranslationFn, questions: Store<MIABQuestionMetadata[]>): Promise<void> {
  const unpackedState = state.unpackUnion();
  assert(unpackedState.kind === "EDIT");
  const { currentLanguage } = useGaContext();
  const popup = await useConfirmationModal()({
    message: t("miab:edit.form.confirm.message") ?? "",
    confirmLabel: t("miab:edit.form.confirm.ok"),
    cancelLabel: t("miab:edit.form.confirm.cancel"),
  });
  if (popup) {
    const result = await saveCustomQuestion({ questions: [...unpackedState.store.get().modifiedQuestions], dataId: dataId });
    if (result.kind !== "VALIDATION_ERROR") {
      if (result.kind === "UPDATED") {
        addToast({
          variant: "success",
          message: t("miab:edit.form.success.message") ?? "",
        });
        questions.set(unpackedState.store.sub("modifiedQuestions").get());
        state.set({ kind: "VIEW" });
      } else {
        window.location.href = window.location.origin + "/" + currentLanguage() + "/miab/" + result.miabId + "?hash=" + result.hash;
      }
    } else {
      unpackedState.store.sub("errors").set(result.validationErrorReasons);
    }
  }
}

function addQuestion(questions: Store<MIABQuestionMetadata[]>): void {
  const highestOrder = [...questions.get()].sort((a, b) => b.sortOrder - a.sortOrder)[0]?.sortOrder ?? 0;
  questions.set([...questions.get(), { title: "", question: "", sortOrder: highestOrder + 1, createdAt: null }]);
}

function EditInformation(props: { t: TranslationFn; username: string; createdAt: DateTime }): GaVueComponent {
  const { t, username, createdAt } = props;
  const { currentLanguage } = useGaContext();
  return (
    <div class="d-flex gap-2 my-3">
      <div class="h4">
        <i class="ico-info-circle" />
      </div>
      {t("miab:page.discuss.editInfo", [username, DateTimeUtils.formatWithLocale(createdAt, currentLanguage())])}
      <p></p>
    </div>
  );
}

export function Discuss(props: { state: Store<DiscussState>; questions: Store<MIABQuestionMetadata[]>; page: MIABPageProps; t: TranslationFn }): GaVueComponent {
  const t = props.t;
  const state = props.state.unpackUnion();
  const { customer, flags } = useGaContext();
  const { miab, linkedMiab } = props.page;
  return (
    <>
      <div class="mb-2">
        <GaBadge variant="orange-light">{t("miab:page.discuss.badge")?.toUpperCase()}</GaBadge>
      </div>
      {miab.userContext !== null ? <EditInformation t={t} createdAt={miab.content.data.createdAt} username={miab.userContext.username} /> : null}
      <div class="d-flex justify-content-between">
        <h2>{t("miab:page.discuss.title")}</h2>
        {state.kind === "VIEW" && ((miab.userContext === null && linkedMiab === null) || (miab.userContext !== null && customer !== null && customer.customerId === miab.userContext.customerId)) && flags().beta ? (
          <div>
            <GaButton variant={"primary-alt"} onClick={() => props.state.set({ errors: { general: [], fields: { additionalProperties: {} } }, modifiedQuestions: [...props.questions.get()], kind: "EDIT" })}>
              {t("miab:edit.form.edit")}
            </GaButton>
          </div>
        ) : null}
      </div>
      <p>
        <strong>{t("miab:page.discuss.description")}</strong>
      </p>
      <div class="miab-questions">
        {state.kind === "VIEW" ? (
          <>
            <GaNumberedList items={props.questions.get().map((question) => ({ title: question.title, text: question.question }))} />
            {linkedMiab !== null && linkedMiab.common.link !== null && (props.page.miab.userContext === null || (customer !== null && customer.customerId === props.page.miab.userContext.customerId)) && flags().beta ? (
              <div class="mt-4">
                <GaAlert variant={"info"}>
                  <p vHtml={miab.userContext === null ? t("miab:page.linkedMiab.original", [linkedMiab.common.link]) : t("miab:page.linkedMiab.edited", [linkedMiab.common.link])}></p>
                </GaAlert>
              </div>
            ) : null}
          </>
        ) : (
          <>
            {state.store.get().errors.general.length > 0 ? (
              <div class="mb-4">
                <GaAlert variant={"danger"}>
                  <ul class="mb-0">
                    {state.store.get().errors.general.map((error) => (
                      <li>{error}</li>
                    ))}
                  </ul>
                </GaAlert>
              </div>
            ) : null}
            <Edit questions={state.store.sub("modifiedQuestions")} errors={state.store.sub("errors")} t={t} />
            <div class="d-flex justify-content-end gap-2">
              {state.store.sub("modifiedQuestions").get().length < MIAB_MAX_QUESTIONS ? (
                <GaButton variant={"primary-alt"} onClick={() => addQuestion(state.store.sub("modifiedQuestions"))}>
                  {t("miab:edit.form.addQuestion")}
                </GaButton>
              ) : null}
              {props.page.miab.userContext == null ? (
                <GaButton variant={"primary-alt"} onClick={() => state.store.sub("modifiedQuestions").set(miab.content.data.questions)}>
                  {t("miab:edit.form.revert")}
                </GaButton>
              ) : null}
              <GaButton variant={"primary"} onClick={() => save(props.state, props.page.miab.content.data.summaryId, t, props.questions)}>
                {t("miab:edit.form.save")}
              </GaButton>
            </div>
          </>
        )}
      </div>
    </>
  );
}
