import type { Store } from "@/common/storeUtils";
import type { MIABQuestionMetadata, ValidationErrorReasons } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaFormFieldInputText } from "@/components/form/GaFormFieldInputText";
import { GaFormFieldTextArea } from "@/components/form/GaFormFieldTextArea";
import { GaButton } from "@/components/general/GaButton";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";
import { MIAB_MIN_QUESTIONS } from "@/components/miab/MIABPage";

function deleteQuestion(sortOrder: number, questions: Store<MIABQuestionMetadata[]>): void {
  const modified = questions.get().filter((question) => question.sortOrder !== sortOrder);
  questions.set(modified);
}

export function Edit(props: { questions: Store<MIABQuestionMetadata[]>; errors: Store<ValidationErrorReasons>; t: TranslationFn }): GaVueComponent {
  return (
    <>
      {props.questions
        .get()
        .map((question) => props.questions.sub(props.questions.get().findIndex((q2) => question.sortOrder === q2.sortOrder)))
        .map((question) => {
          const fieldNamePrefix = "question" + question.get().sortOrder + ".";
          return (
            <div class="mb-4 miab-questions__edit">
              <div class="miab-questions__edit-title">
                <GaFormFieldInputText
                  value={question.sub("title").get()}
                  onUpdateValue={(value) => {
                    question.sub("title").set(value);
                    props.errors
                      .sub("fields")
                      .sub("additionalProperties")
                      .sub(fieldNamePrefix + "title")
                      .set([]);
                  }}
                  name="title"
                  placeholder={props.t("miab:edit.form.title")}
                  errors={props.errors.get().fields.additionalProperties[fieldNamePrefix + "title"]}
                />
              </div>
              <div class="miab-questions__edit-question">
                <GaFormFieldTextArea
                  value={question.get().question}
                  onUpdateValue={(value) => {
                    question.sub("question").set(value);
                    props.errors
                      .sub("fields")
                      .sub("additionalProperties")
                      .sub(fieldNamePrefix + "question")
                      .set([]);
                  }}
                  name="question"
                  placeholder={props.t("miab:edit.form.question")}
                  errors={props.errors.get().fields.additionalProperties[fieldNamePrefix + "question"]}
                />
              </div>
              <div class="miab-questions__edit-delete">
                <GaButton variant={"primary-alt"} onClick={() => deleteQuestion(question.get().sortOrder, props.questions)} disabled={props.questions.get().length <= MIAB_MIN_QUESTIONS}>
                  <i class="ico-trash-bin" />
                </GaButton>
              </div>
            </div>
          );
        })}
    </>
  );
}
