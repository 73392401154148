import { defineComponent, onBeforeMount, type PropType } from "vue";
import type { ActionableActionbarProps, ContentMetadataActionable } from "@newgenerated/shared/schema";
import { GaButton } from "@/components/general/GaButton";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import { createStore, type Store } from "@/common/storeUtils";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaSpinner } from "@/components/general/GaSpinner";
import { useEnrichUrl } from "@utils/routeUtils";
import { useCustomModal } from "@/components/general/GaModal";
import { ContentCard, type TranslationFnAsync } from "@/components/cards/ContentCard";
import { GaFormFieldInputText } from "@/components/form/GaFormFieldInputText";
import { addToast } from "@/components/general/GaToast";
import { trackOutboundLinkClick, trackShareEvent } from "@generated/api/analyticTrackingControllerApi";
import { openActionableLoginModal } from "@/components/actionable/actionable-page/LoginModal";
import { getShareLinks } from "@/common/shareUtils";
import { getPageViewMeta } from "@/components/analytic/_outbound-link-tracking";
import { toggleBookmarkActionable } from "@generated/api/actionableCustomerIxApiControllerApi";
import { getInitialSuspendState } from "@/common/GaSuspense";

export type UserPortal = {
  corporateId: number;
  username: string;
  canSubscribe: boolean;
  smb: boolean;
  needsPrivacyPolicy: boolean;
};

type ActionableActionbarLoadedState = {
  kind: "LOADED";
  t: TranslationFn;
  hasActionableFeature: boolean;
  bookmarked: boolean;
  translationFn: TranslationFnAsync;
};
type ActionableActionbarState = ActionableActionbarLoadedState | { kind: "LOADING" };

function getDeviceType(): string {
  const viewportWidth = window.innerWidth;
  const DEVICE_MD = 792;
  const DEVICE_LG = 1260;
  if (viewportWidth < DEVICE_MD) {
    return "mobile";
  } else if (viewportWidth > DEVICE_MD && viewportWidth < DEVICE_LG) {
    return "tablet";
  } else {
    return "desktop";
  }
}

async function copyToClipboard(actionableLink: string, successMessage: string, actionableId: bigint): Promise<void> {
  await navigator.clipboard.writeText(actionableLink);
  addToast({
    message: successMessage,
  });
  await trackShareEvent({ id: actionableId, contentItemType: "ACTIONABLE", shareMedium: "COPY_LINK" });
}

async function openSharePopup(
  actionable: ContentMetadataActionable,
  name: string,
  t: TranslationFn,
  userPortal: UserPortal | null,
  userCustomerId: number | null,
  freeView: boolean,
  translationFnAsync: Store<TranslationFnAsync>,
): Promise<void> {
  const actionableLink = new URL(`/${actionable.common.language}${freeView ? "/free-actionables" : "/actionables"}/${name}/${actionable.contentId.id}`, window.location.origin);
  if (userPortal !== null && !freeView) {
    actionableLink.searchParams.set("u", userPortal.username);
  }
  actionableLink.searchParams.set("utm_source", `intUser-${userPortal !== null ? userPortal.corporateId : 0}-${userCustomerId ?? 0}`);
  actionableLink.searchParams.set("utm_medium", `${getDeviceType()}-web`);
  actionableLink.searchParams.set("utm_campaign", "actionable-share");
  actionableLink.searchParams.set("utm_term", "copy-link");
  actionableLink.searchParams.set("utm_content", name);
  await useCustomModal()({
    body: () => (
      <div class="actionable__share-popup__container">
        <div class="actionable__share-popup__description">
          <h3>{t("actionable:share.popup.title")}</h3>
          <p>{t("actionable:share.popup.description")}</p>
        </div>
        <div class="actionable__share-popup__card">
          <ContentCard content={actionable} link={actionableLink.toString()} layout={"vertical"} translationFn={translationFnAsync} />
        </div>
        <div class="actionable__share-popup__share-form">
          <p class="caption">{t("actionable:share.popup.caption")}</p>
          <GaFormFieldInputText name="actionableShareLink" value={actionableLink.href} onUpdateValue={() => void 0} disabled={true} />
          <div class="actionable__share-popup__share-button">
            <GaButton onClick={() => copyToClipboard(actionableLink.href, t("actionable:share.notification.successful") ?? "", actionable.contentId.id)}>{t("actionable:share.copylink")}</GaButton>
          </div>
        </div>
      </div>
    ),
    size: "lg",
  });
}

export const OPEN_CERTIFICATE_POPUP_EVENT = "ga.actionable.certificatePopupOpen";
export const OPEN_CERTIFICATE_ANCHOR_ELEMENT_SELECTOR = "[data-ga-actionable-open-certificate-popup]";

function openCertificate(): void {
  const anchor = document.querySelector(OPEN_CERTIFICATE_ANCHOR_ELEMENT_SELECTOR);
  const element = document.querySelector("[data-ga-actionable-step-active='reflect']");
  const steps = document.querySelector("#actionable-steps");
  const step3 = document.querySelector("#collapse3");
  if (steps != null && !steps.classList.contains("show")) {
    steps.classList.add("show");
  }
  if (step3 != null && !step3.classList.contains("show")) {
    step3.classList.add("show");
  }
  if (element !== null) {
    element.scrollIntoView();
  }
  const event = new Event(OPEN_CERTIFICATE_POPUP_EVENT);
  anchor?.dispatchEvent(event);
}

async function trackScheduleEventLink(dataId: bigint, url: string, targetName: string): Promise<void> {
  const meta = getPageViewMeta();
  if (meta !== null) {
    await trackOutboundLinkClick({ id: Number(dataId), targetUrl: url, targetName: targetName, type: "ACTIONABLE_SCHEDULE", meta: meta });
  }
}

async function toggleBookmark(actionableId: bigint, bookmarkStore: Store<boolean>): Promise<void> {
  bookmarkStore.set((await toggleBookmarkActionable(Number(actionableId))).bookmarked);
}

export function Content(props: { pageProps: ActionableActionbarProps; store: Store<ActionableActionbarLoadedState> }): GaVueComponent {
  const { currentLanguage } = useGaContext();
  const t = props.store.sub("t").get();
  const enrichUrl = useEnrichUrl;
  const title = t("actionable:scheduleCalendar.title", [props.pageProps.contentMetadata.common.title]) ?? "";

  function getDescription(provider: string): string {
    const link = new URL(`/${currentLanguage()}${props.pageProps.freeActionableActive ? "/free-actionables" : "/actionables"}/${props.pageProps.actionableName}/${props.pageProps.contentMetadata.contentId.id}`, window.location.origin);
    if (props.pageProps.userPortalUsername !== null) {
      link.searchParams.set("u", props.pageProps.userPortalUsername);
    }
    link.searchParams.set("utm_medium", "Calendar");
    link.searchParams.set("utm_content", "Actionable");
    link.searchParams.set("utm_source", provider);
    return t("actionable:scheduleCalendar.description", [props.pageProps.contentMetadata.common.title, link.toString()], "");
  }

  const links = getShareLinks(title, (provider) => getDescription(provider));
  const userPortal =
    props.pageProps.userPortalUsername !== null && props.pageProps.userPortalCorporateId !== null
      ? {
          username: props.pageProps.userPortalUsername,
          corporateId: props.pageProps.userPortalCorporateId,
          canSubscribe: props.pageProps.userPortalCanSubscribe,
          smb: props.pageProps.userPortalSmb,
          needsPrivacyPolicy: props.pageProps.userPortalNeedsPrivacyPolicy,
        }
      : null;
  return (
    <>
      <div class="actionable-header__buttons">
        {props.pageProps.showCertificate ? (
          <GaButton variant="primary" onClick={() => openCertificate()}>
            <i class="ico-certificate" />
            &nbsp;{t("actionable:page.certificate.accessCertificate")}
          </GaButton>
        ) : (
          <>
            <div class="dropdown">
              <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
                <i class="ico-calendar" />
                &nbsp;{t("actionable:scheduleCalendar.button")}
              </button>
              <ul class="dropdown-menu">
                {links.map((shareLink) => (
                  <li>
                    <a class="dropdown-item" href={shareLink.href} onClick={() => trackScheduleEventLink(props.pageProps.contentMetadata.contentId.id, shareLink.href, shareLink.title)} target="_blank">
                      {shareLink.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
        {props.pageProps.canAccessCourse && props.pageProps.userCustomerId !== null ? (
          <GaButton variant="primary-alt" onClick={() => toggleBookmark(props.pageProps.contentMetadata.contentId.id, props.store.sub("bookmarked"))}>
            <i class={props.store.sub("bookmarked").get() ? "ico-bookmark-fill" : "ico-bookmark"} />
            &nbsp;{t("actionable:bookmark.button")}
          </GaButton>
        ) : !props.store.sub("hasActionableFeature").get() || props.pageProps.userCustomerId === null ? (
          <GaButton variant="primary-alt" onClick={() => openActionableLoginModal(userPortal, props.pageProps.userCustomerId, props.pageProps.contentMetadata, props.pageProps.actionableName)}>
            <i class="ico-bookmark" />
            &nbsp;{t("actionable:bookmark.button")}
          </GaButton>
        ) : null}
        {props.pageProps.canAccessCourse ? (
          <GaButton
            variant="primary-alt"
            onClick={() => openSharePopup(props.pageProps.contentMetadata, props.pageProps.actionableName, t, userPortal, props.pageProps.userCustomerId, props.pageProps.freeActionableActive, props.store.sub("translationFn"))}>
            <i class="ico-share-alt2" />
            &nbsp;{t("actionable:share.button")}
          </GaButton>
        ) : !props.store.sub("hasActionableFeature").get() || props.pageProps.userCustomerId === null ? (
          <GaButton variant="primary-alt" onClick={() => openActionableLoginModal(userPortal, props.pageProps.userCustomerId, props.pageProps.contentMetadata, props.pageProps.actionableName)}>
            <i class="ico-share-alt2" />
            &nbsp;{t("actionable:share.button")}
          </GaButton>
        ) : null}
        {props.pageProps.actionableSet.length > 0 ? (
          <div class="dropdown">
            <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
              <i class="ico-language"></i>
              &nbsp;{t("actionable:switchLanguage.button")}
            </button>
            <ul class="dropdown-menu">
              {props.pageProps.actionableSet.map((setEntry) => (
                <li>
                  <a class="dropdown-item" href={enrichUrl(`/actionables/${setEntry.name}/${setEntry.actionableId}`)}>
                    {t(`general:language.${setEntry.language}`)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </>
  );
}

export const ActionableActionbar = defineComponent({
  props: {
    actionableActionbarProps: {
      type: Object as PropType<ActionableActionbarProps>,
      required: true,
    },
  },
  setup: (props) => {
    const { loadTranslation, hasFeature } = useGaContext();
    const store = createStore<ActionableActionbarState>({
      kind: "LOADING",
    });
    onBeforeMount(async () => {
      store.set({
        kind: "LOADED",
        t: await loadTranslation(props.actionableActionbarProps.language),
        hasActionableFeature: await hasFeature("ACTIONABLE"),
        bookmarked: props.actionableActionbarProps.bookmarked,
        translationFn: getInitialSuspendState<TranslationFn>(),
      });
    });
    return () => {
      const loadedState = store.unpackUnion();
      return loadedState.kind === "LOADING" ? (
        <div style="display: flex; justify-content: center; align-items: center; height: 1000px;">
          <GaSpinner />
        </div>
      ) : (
        <Content pageProps={props.actionableActionbarProps} store={loadedState.store} />
      );
    };
  },
});
