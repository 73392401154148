import type { AskGetabstractQuestion, AskGetabstractQuestionAnswers, ChannelMetadata, ContentMetadata, CustomPageListView, FeatureType, FormFilter, FullSearchResults, SearchFiltersForm } from "@newgenerated/shared/schema";
import type { ContentTypes } from "@/components/instant-search/_instant-search";
import type { MultiSelectStateProps } from "@/components/form/GaFormFieldMultiSelect";
import type { SimpleComponentUiState } from "@/components/ask-getabstract/utils/backgroundWorker";
import type { TranslationFnsAsync } from "@/components/cards/ContentCard";

export type FullSearchStoreApiMethods = {
  search: (searchFiltersForm: SearchFiltersForm) => Promise<FullSearchResults>;
  createQuestion: (question: AskGetabstractQuestion) => Promise<void>;
  getQuestionAnswers: (questionUuid: string) => Promise<AskGetabstractQuestionAnswers>;
  hasFeature: (feature: FeatureType) => Promise<boolean>;
};

export const defaultAmount: { [content in ContentTypes]: number } = {
  SUMMARY: 4,
  ACTIONABLE: 6,
  CHANNEL: 4,
  CUSTOMPAGE: 9,
};

export const contentTypeOrder: { [content in ContentTypes]: number } = {
  SUMMARY: 1,
  ACTIONABLE: 2,
  CHANNEL: 3,
  CUSTOMPAGE: 4,
};

export type FormFilterExtended = FormFilter & {
  isCollapsed: boolean;
  multiSelectProps: MultiSelectStateProps<string> | null;
};

export type ContentTypeDiscriminator = "SUMMARY" | "CHANNEL" | "CUSTOMPAGE" | "ACTIONABLE";

export type DataType =
  | {
      kind: "SUMMARY";
      items: ContentMetadata[];
    }
  | {
      kind: "CHANNEL";
      items: ChannelMetadata[];
    }
  | {
      kind: "CUSTOMPAGE";
      items: CustomPageListView[];
    }
  | {
      kind: "ACTIONABLE";
      items: ContentMetadata[];
    };

export type ContentTypeFilterProps = {
  formFilters: FormFilterExtended[];
  formFilterExtended: boolean;
};

export type ContentTypeProps = DataType & {
  amountToShow: number;
  title: string;
  filterProps: ContentTypeFilterProps;
  paging: {
    page: number;
    totalCount: bigint;
  };
};

export type ExtendedContentType = ContentTypes | "ALL";

export type SearchAiComponentState =
  | { kind: "NOT_AVAILABLE" }
  | {
      kind: "AVAILABLE";
      uiState: SimpleComponentUiState;
      displayFullAnswer: boolean;
    };

export type SearchStatus = "LOADING" | "LOADMORE" | "IDLE" | "ERROR";

export type SearchProps = {
  status: SearchStatus;
  params: URLSearchParams;
  searchTerm: string;
  selectedContentType: ExtendedContentType;
};

export type SearchStoreState = {
  contentTypeProps: ContentTypeProps[];
  searchProps: SearchProps;
  aiState: SearchAiComponentState;
  translationFns: TranslationFnsAsync;
};
